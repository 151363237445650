.auth-page-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sign-up-card {
    border-radius: var(--border-radius);
    border: var(--primary-border);
    padding: 30px;
    width: 300px;
}

.auth-header {
    text-align: center;
    margin: 10px;
    color: var(--primary-clr);
}

.auth-form {
    width: 100%;
}

.auth-form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 20px 0px;
    font-weight: 600;
}

.auth-form-label {
    margin-bottom: 5px;
}

.auth-form-input {
    width: calc(100% - 20px);
    border-radius: var(--border-radius);
    border: var(--primary-border);
    height: 35px;
    padding: 0px 10px;
}

.button-stretch {
    width: 100%;
    height: 35px;
    font-weight: 600;
    border-radius: var(--border-radius);
    background-color: var(--primary-bg);
    border: none;
    background-color: var(--primary-clr);
    color: white;
}

.switch-auth-domain {
    margin-top: 10px;
}

.error {
    display: flex;
    align-items: center;
    font-weight: 600;
    background-color: var(--error-bg);
    color: var(--error-clr);
    height: 40px;
    border-radius: var(--border-radius);
    padding: 0px 10px;
}
:root {
    --primary-bg: white;
    --primary-clr: #2A1D6A;
    --secundary-clr: #E5EFFC;
    --error-bg: #F7D6DA;
    --success-bg: #d6f7d6;
    --error-clr: #9B6F76;
    --success-clr: #6f9b79;
    --muted: #BABAC5;


    --border-radius: 5px;
    --primary-border: 1px solid #B3BAD4;
}
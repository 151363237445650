body {
  overflow-x: hidden;
  left: 0;
  right: 0;
  margin: 0;
  min-height: 100%;
  font-family: 'Gilroy';
  background-color: white;
  color: var(--primary-clr);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-content {
  padding: 0px 20px;
  width: 100%;
}

.dashboard-wrapper {
  padding: 40px;
  display: flex;
  flex-direction: row;
}

.side-menu {
  width: 250px;
  border-right: var(--primary-border)
}

.menu-link-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.menu-link-content-wrapper {
  display: flex;
  flex-direction: row;
}

.menu-link-icon {
  width: 20px;
  filter: invert(80%) sepia(4%) saturate(467%) hue-rotate(201deg) brightness(95%) contrast(85%);
  margin-right: 20px;
}

.menu-link-text {
  color: var(--muted);
  font-weight: bold;
}

.menu-link-wrapper.active img {
    filter: invert(10%) sepia(71%) saturate(2451%) hue-rotate(240deg) brightness(103%) contrast(101%);
}

.menu-link-wrapper.active p {
  color: var(--primary-clr);
}

.menu-link-wrapper.active .active-link-indicator {
  height: 30px;
  width: 7px;
  background-color: var(--primary-clr);
  border-radius: var(--border-radius);
}

.logo {
  font-weight: 1000;
  color: var(--primary-clr);
  margin-top: 0px;
  margin-bottom: 40px;
}

.menu-collection-title {
  color: var(--primary-clr);
  opacity: 90%;
  margin: 5px 0px;
}

.menu-collection {
  margin-bottom: 40px;
}

label {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
}

.error {
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: var(--error-bg);
  color: var(--error-clr);
  height: 40px;
  border-radius: var(--border-radius);
  padding: 0px 10px;
}

.success {
  display: flex;
  align-items: center;
  font-weight: 600;
  background-color: var(--success-bg);
  color: var(--success-clr);
  height: 40px;
  border-radius: var(--border-radius);
  padding: 0px 10px;
}

.form-input-wrapper {
  display: flex;
  flex-direction: row;
}

.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 35px;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 600;
  border-radius: var(--border-radius);
  background-color: var(--primary-bg);
  border: none;
  background-color: var(--primary-clr);
  color: white;
}

.form-input {
  width: 400px;
  border-radius: var(--border-radius);
  border: var(--primary-border);
  height: 35px;
  padding: 0px 10px;
}

.current-friend-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius);
  border: var(--primary-border);
  padding: 15px;
  margin-bottom: 15px;
}

.current-friend-left-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.current-friend-profile-picture {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
}

.current-friend-info {
  margin-left: 10px;
  margin-right: 60px;
}

.current-friend-name {
  margin: 0px;
}

.current-friend-email {
  margin: 0px;
}

.current-friend-remove {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D11514;
  border-radius: var(--border-radius);
  margin-left: 5px;
}

.current-friend-accept {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #30d114;
  border-radius: var(--border-radius);
}

.current-friend-edit-icon {
  width: 50%;
}

.friends-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.friends-section-wrapper {
  margin-right: 20px;
}